import React, { useState, useEffect, useCallback } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'
import axios from 'axios'
import useSWR from 'swr'
import Link from 'next/link'

import {
  ProductGallery,
  ProductPrice,
  ProductForm,
  ProductActions,
} from '@blocks/product'
import { useI18nContext } from '@lib/i18n'
import Icon from '@components/icon'
import { canonicalUrl } from '@components/link'

// setup our inventory fetcher
const fetchInventory = (url, id) => {
  return axios
    .get(url, {
      params: {
        id: id,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => console.log('error', e))
}

const CandleProperties = ({ candleProperties }) => {
  if (!candleProperties) {
    return null
  }

  const { burnTime, dimensions, inclusions } = candleProperties
  const properties = [burnTime, dimensions, inclusions]
  if (properties.filter(Boolean).length == 0) {
    return null
  }
  const { t } = useI18nContext()

  return (
    <p className="product--candle-properties">
      {properties.map((property, i) => {
        if (property === burnTime) {
          return (
            <>
              {t(`Burn time: ~${burnTime} hours`, `燃焼時間: ${burnTime}時間`)}
              <br />
            </>
          )
        } else {
          return (
            <>
              {property}
              <br />
            </>
          )
        }
      })}
    </p>
  )
}

const ProductHero = ({
  product: initialProduct,
  activeVariantId,
  onVariantChange: customOnVariantChangeId,
  showSummary,
}) => {
  const [product, setProduct] = useState(initialProduct)
  // set active variant as default
  const [localActiveVariantId, setActiveVariantId] = useState(
    activeVariantId ? activeVariantId : product.variants[0].id
  )

  const onVariantChange = (id) => {
    const newActiveVariant = product.variants.find((v) => v.id === id)
    customOnVariantChangeId
      ? customOnVariantChangeId(newActiveVariant.id)
      : setActiveVariantId(newActiveVariant.id)
  }

  // Check our product inventory is still correct
  const { data: productInventory } = useSWR(
    ['/api/shopify/product-inventory', product.id],
    (url, id) => fetchInventory(url, id),
    { errorRetryCount: 3 }
  )

  useEffect(() => {
    if (product && productInventory) {
      setProduct({
        ...product,
        inStock: productInventory.inStock,
        lowStock: productInventory.lowStock,
        variants: [
          ...product.variants.map((v) => {
            const newInventory = productInventory.variants.find(
              (nv) => nv.id === v.id
            )
            return newInventory ? { ...v, ...newInventory } : v
          }),
        ],
      })
    }
  }, [initialProduct, productInventory])

  const activeVariant = product.variants.find(
    (v) => v.id == (activeVariantId ?? localActiveVariantId)
  )
  const { t } = useI18nContext()

  const productTitle = (
    <>
      {activeVariant && (
        <div className="product--variant">
          {activeVariant.title !== 'Default Title' && activeVariant.title}

          {!activeVariant.inStock && (
            <span className="label is-secondary">
              {t(`Out of stock`, `在庫切れ`)}
            </span>
          )}
        </div>
      )}
      <h1 className="product--name">{product.title}</h1>
    </>
  )

  const description = (
    <BlockContent
      renderContainerOnSingleChild
      className="rc"
      blocks={
        showSummary && product.shortDescription
          ? product.shortDescription
          : product.description
      }
      serializers={serializers}
    />
  )
  const productUrl = canonicalUrl(
    `/products/${
      product.slug +
      (product.surfaceOption ? `?variant=${activeVariant.id}` : '')
    }`
  )
  return (
    <section className="product">
      <div className="product--content">
        <div className="product--gallery">
          {showSummary ? (
            <Link href={productUrl} scroll={false}>
              <a>
                {
                  <ProductGallery
                    hasDrag={false}
                    photosets={product.photos.main}
                    activeVariant={activeVariant}
                  />
                }
              </a>
            </Link>
          ) : (
            <ProductGallery
              photosets={product.photos.main}
              activeVariant={activeVariant}
              hasArrows
              hasCounter
              hasThumbs
            />
          )}
        </div>

        <div className="product--details">
          <div className="product--info">
            <div className="product--header">
              <div className="product--title">
                {showSummary ? (
                  <Link href={productUrl} scroll={false}>
                    <a>{productTitle}</a>
                  </Link>
                ) : (
                  productTitle
                )}
              </div>
            </div>
            <ProductPrice
              price={activeVariant?.price || product.price}
              comparePrice={activeVariant?.comparePrice || product.comparePrice}
            />
            <div className="product--desc">
              {showSummary ? (
                <Link href={productUrl} scroll={false}>
                  <a>{description}</a>
                </Link>
              ) : (
                <>
                  {description}
                  <CandleProperties
                    candleProperties={product.candleProperties}
                  />
                </>
              )}
            </div>

            <ProductForm
              product={product}
              activeVariant={activeVariant}
              onVariantChange={onVariantChange}
              className="product--form"
            />
            {showSummary && (
              <Link
                href={`/products/${
                  product.slug +
                  (product.surfaceOption ? `?variant=${activeVariant.id}` : '')
                }`}
                scroll={false}
              >
                <a className="product--learn-more">
                  <span>{t(`Learn more`, `さらに詳しく`)}</span>{' '}
                  <Icon viewBox="0 0 24 24" name="ChevronRight" />
                </a>
              </Link>
            )}
            <ProductActions
              showLearnMore={false}
              slug={product.slug}
              surfaceOption={product.surfaceOption}
              activeVariant={activeVariant}
              klaviyoAccountID={product.klaviyoAccountID}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductHero
