import React, { useState } from 'react'
import Link from 'next/link'

import { ProductAdd, ProductWaitlist } from '@blocks/product'
import { useI18nContext } from '@lib/i18n'
import { canonicalUrl } from '@components/link'

const ProductActions = ({
  activeVariant,
  klaviyoAccountID,
  slug,
  showLearnMore,
  surfaceOption,
}) => {
  // set default quantity
  const [quantity, setQuantity] = useState(1)
  const { t } = useI18nContext()
  return (
    <div className="product--actions">
      {activeVariant?.inStock ? (
        <>
          {/* <ProductCounter
            id={activeVariant.id}
            max={10}
            onUpdate={setQuantity}
          /> */}
          <ProductAdd productID={activeVariant.id} quantity={quantity}>
            {t(`Add to cart`, `カートに追加する`)}
          </ProductAdd>
          {showLearnMore && (
            <Link
              href={canonicalUrl(
                `/products/${
                  slug + (surfaceOption ? `?variant=${activeVariant.id}` : '')
                }`
              )}
              scroll={false}
            >
              <a className="product--actions-learn-more">
                {t(`Learn more`, `さらに詳しく`)}
              </a>
            </Link>
          )}
        </>
      ) : (
        <>
          {klaviyoAccountID ? (
            <ProductWaitlist
              variant={activeVariant.id}
              klaviyo={klaviyoAccountID}
            />
          ) : (
            <div className="btn is-large is-disabled is-block">
              {t(`Out of stock`, `在庫切れ`)}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ProductActions
