import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { m, AnimatePresence } from 'framer-motion'
import { useI18nContext } from '@lib/i18n'

const Waitlist = ({ variant, klaviyo }) => {
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { handleSubmit, register, reset, errors } = useForm()
  const { t } = useI18nContext()

  const resetForm = (e) => {
    e.preventDefault()
    reset()
    setError(false)
    setSuccess(false)
    setSubmitting(false)
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    if (!variant) {
      setError(true)
      return
    }

    setSubmitting(true)
    setError(false)

    axios
      .post('/api/klaviyo/waitlist-join', {
        accountID: klaviyo,
        variant: variant,
        ...data,
      })
      .then(() => {
        setSubmitting(false)
        setSuccess(true)
      })
      .catch((error) => {
        setSubmitting(false)
        setError(true)
        console.error(error)
      })
  }

  const formAnim = {
    show: {
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: 'linear',
        when: 'beforeChildren',
      },
    },
    hide: {
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: 'linear',
        when: 'afterChildren',
      },
    },
  }

  return (
    <div className="product--waitlist">
      <AnimatePresence exitBeforeEnter initial={false}>
        {!error && !success && (
          <m.form
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={formAnim}
            className="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="product--waitlist-description">
              {t(
                `Out of Stock. Get notified when this product is back in stock.`,
                `現在欠品中です。メールで入荷のご連絡をいたします。`
              )}
            </p>
            <input
              type="text"
              name="fullname"
              autoComplete="off"
              className="control--pot"
              ref={register}
            />
            <div className="control--group is-inline">
              <div className={`control${errors.email ? ' has-error' : ''}`}>
                <label htmlFor="email" className="control--label">
                  Email Address
                </label>
                <input
                  name="email"
                  type="email"
                  inputMode="email"
                  autoComplete="email"
                  ref={register({
                    required: t(
                      'This field is required',
                      'このフィールドは必須です。'
                    ),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t(
                        'Verify that your email address is correct',
                        'メールアドレスが正しいことを確認します。'
                      ),
                    },
                  })}
                  onFocus={(e) => {
                    e.target.parentNode.classList.add('is-filled')
                  }}
                  onBlur={(e) => {
                    const value = e.target.value
                    e.target.parentNode.classList.toggle('is-filled', value)
                  }}
                  onChange={(e) => {
                    const value = e.target.value
                    e.target.parentNode.classList.toggle('is-filled', value)
                  }}
                />
                {errors.email && (
                  <span role="alert btn is-primary" className="control--error">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <button
                type="submit"
                className={`btn is-primary is-block${
                  submitting ? ' is-loading' : ''
                }`}
                disabled={submitting}
              >
                {submitting ? '...' : t('Register', '登録')}
              </button>
            </div>
          </m.form>
        )}

        {success && (
          <m.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={formAnim}
            className="form--success"
          >
            <div className="form--success-content">
              {t(
                `Thank you for registering.`,
                `メールアドレスのご登録をいただき、ありがとうございました。`
              )}
            </div>
          </m.div>
        )}

        {error && (
          <m.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={formAnim}
            className="form--error"
          >
            <div className="form--error-content">{t('Error', 'エラー')}</div>
            <div className="form--error-reset">
              <button className="btn" onClick={(e) => resetForm(e)}>
                {t('Try Again', '戻る')}
              </button>
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Waitlist
