import React from 'react'
import cx from 'classnames'

import { useSiteContext, useAddItem } from '@lib/context'
import { useI18nContext } from '@lib/i18n'

const ProductAdd = ({ productID, quantity = 1, className, children }) => {
  const addItemToCart = useAddItem()
  const { shopifyClient, isLoading, isAdding } = useSiteContext()

  // Check that Shopify is connected
  if (!shopifyClient) {
    return (
      <span className={cx('is-disabled', className)} disabled>
        Unavailable
      </span>
    )
  }

  const { t } = useI18nContext()

  return (
    <>
      {isLoading ? (
        <button className={cx('is-disabled', className)} disabled>
          Loading...
        </button>
      ) : (
        <button
          className={cx(className, { 'is-disabled': isAdding })}
          onClick={() => addItemToCart(productID, quantity)}
        >
          {isAdding ? (
            t(`Adding to cart...`, 'カートに入れる...')
          ) : (
            <>{children ? children : t(`Add to cart`, `カートに追加する`)}</>
          )}
        </button>
      )}
    </>
  )
}

export default ProductAdd
